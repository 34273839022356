/* import '@mantine/core/styles.css'; */
.loader {
    width: 32px;
    height: 32px;
    border: 5px solid #f3f3f3;
    border-bottom-color: transparent;
    border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 